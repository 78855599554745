var jQueryBridget = require('jquery-bridget');
var Isotope = require('isotope-layout');
jQueryBridget( 'isotope', Isotope, $ );

var imagesLoaded = require('imagesloaded');
imagesLoaded.makeJQueryPlugin( $ );
window.imagesLoaded = imagesLoaded;

// Isotope
var Isotope = (function() {
    // Variables
    var $grid = $('.grid, [data-isotope]'),
        $filter = $('[data-filter]'),
        $options = {
            itemSelector: '.grid-item',
            layoutMode: 'masonry'
        };


    // Methods
    function init($this) {
        $grid.imagesLoaded( function() {
            // init Isotope after all images have loaded

            // var iso = new isotope( '.grid, [data-isotope]', $options);
            $grid.isotope($options);
        });
    }

    function filter($this) {
        var $filterValue = $this.data('filter'),
            filterOptions = {
                filter: $filterValue
            },
            $options = $.extend(filterOptions, $options);

        // var iso = new isotope( '.grid, [data-isotope]', $options);
        // console.log(iso);

        $grid.isotope($options);

        $filter.removeClass('active');
        $this.addClass('active');

        // Message if no result
        if(!$grid.data('isotope').filteredItems.length){
            $grid.append('<p class="no-grid-result text-center text-600 py-8">Unfortunately there is no result!</p>')
        } else {
            $grid.find('.no-grid-result').remove();
        }
    }


    // Events
    if($grid.length) {
        init($(this));

        if($filter.length) {
            $filter.on({
                'click': function() {
                    filter($(this));
                }
            })
        }
    }

}());
