// Sticky

var Sticky = (function() {
    //Variables
    var $sticky = $('[data-toggle="sticky"]');


    // Methods
    function init($this){
        var $width = $(window).width(),
            $mobile = typeof $this.data('sticky-disable-mobile') != 'undefined' ? $this.data('sticky-disable-mobile') : true;

        if($mobile) {
            if ($width >= 992) {
                stick($this);
            } else {
                unstick($this);
            }
        } else {
            stick($this);
        }
    }

    function stick($this) {
        var $offsetTop = $this.data('sticky-offset-top') || 0,
            $parentSelector = $this.data('sticky-parent') || 'section',
            $bottoming = typeof $this.data('sticky-bottom') != 'undefined' ? $this.data('sticky-bottom') : true;

        $this.stick_in_parent({
            'parent': $parentSelector,
            'offset_top': $offsetTop,
            'bottoming': $bottoming
        });
    }

    function unstick($this) {
        $this.trigger("sticky_kit:detach");
    }


    // Events
    if($sticky.length) {
        $sticky.each(function() {
            init($(this));
        });

        $(window).on({
            'resize': function() {
                $sticky.each(function() {
                    init($(this));
                });
            }
        })
    }
}());
